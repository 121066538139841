import $ from "jquery";
import "slick-carousel";

$('a[href^="#"]').on("click", function (event) {
  var target = $(this.getAttribute("href"));
  if (target.length) {
    event.preventDefault();
    $("html, body").stop().animate(
      {
        scrollTop: target.offset().top,
      },
      500,
    );
  }
});

var carousel = document.querySelector("[data-carousel=true]");
var carouselArrows = document.querySelector("[data-carousel-arrows=true]");

var prevArrow =
  '<svg aria-label="Previous" role="img" xmlns="http://www.w3.org/2000/svg" width="42px" height="42px" viewBox="0 0 512 512" class="svg-inline--fa fa-arrow-circle-left fa-w-16 fa-2x"><path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zM256 472c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm-12.5-92.5l-115.1-115c-4.7-4.7-4.7-12.3 0-17l115.1-115c4.7-4.7 12.3-4.7 17 0l6.9 6.9c4.7 4.7 4.7 12.5-.2 17.1L181.7 239H372c6.6 0 12 5.4 12 12v10c0 6.6-5.4 12-12 12H181.7l85.6 82.5c4.8 4.7 4.9 12.4.2 17.1l-6.9 6.9c-4.8 4.7-12.4 4.7-17.1 0z" class=""></path></svg>';
var nextArrow =
  '<svg aria-label="Next" role="img" xmlns="http://www.w3.org/2000/svg" width="42px" height="42px" viewBox="0 0 512 512"><path fill="currentColor" d="M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zM256 40c118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216zm12.5 92.5l115.1 115c4.7 4.7 4.7 12.3 0 17l-115.1 115c-4.7 4.7-12.3 4.7-17 0l-6.9-6.9c-4.7-4.7-4.7-12.5.2-17.1l85.6-82.5H140c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12h190.3l-85.6-82.5c-4.8-4.7-4.9-12.4-.2-17.1l6.9-6.9c4.8-4.7 12.4-4.7 17.1 0z" class=""></path></svg>';

$(carousel).slick({
  slidesToShow: 5,
  slidesToScroll: 5,
  infinite: false,
  appendArrows: carouselArrows,
  prevArrow: prevArrow,
  nextArrow: nextArrow,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
});

$(document).ready(function () {
  if (document.referrer) {
    if (document.referrer.indexOf("lodlaw") === -1) {
      localStorage.setItem("referrer", document.referrer);
      // eslint-disable-next-line no-console
      console.log(document.referrer + "stored");
    } else {
      // eslint-disable-next-line no-console
      console.log("referrer not stored, lodlaw");
    }
  } else {
    // eslint-disable-next-line no-console
    console.log("referrer not stored, empty");
  }
});
